import React from 'react';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { StatisticsBlock } from '../../components/V2/StatisticsBlock/StatisticsBlock';
import { HighlightBlockNoImage } from '../../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { LogoGrid } from '../../components/V2/LogoGrid/LogoGrid';
import { FooterButtonOverlay } from '../../components/V2/FooterButtonOverlay/FooterButtonOverlay';
import { ClientSpotlight } from '../../components/V2/ClientSpotlight/ClientSpotlight';
import { AnchoredContent } from '../../components/V2/AnchoredContent/AnchoredContent';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';

import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { BackgroundCircles, BackgroundGrey, BackgroundHalfGrey } from './../../GlobalStyles.jsx';

const HealthcareDE = () => {
  const Lang = 'de';
  const data = require(`../../../data/content/Pages/healthcare/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer
        lang={Lang}
        image="/assets/images/dental/dental.png"
        currentPage={'dental'}
        title={data.helmet.title}
        description={data.helmet.description}
      />
      <Header lang={Lang} />

      <LargeHero
        Country={Lang}
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Logo={'manufacturing-os'}
        Copy={data.content.Hero.copy}
        Background={'/assets/images/dental/dental.png'}
        Button={{
          SourceID: 'request-trial',
          copy: globalCopy.Buttons.RequestDemo,
          OpenDemoModel: true
        }}
      />

      <BackgroundCircles>
        <StatisticsBlock
          Items={[
            {
              order: 'row',
              description: data.content.Statistics.item_1.description,
              lines: [
                {
                  copy: data.content.Statistics.item_1.copy_1,
                  type: ''
                },
                {
                  copy: data.content.Statistics.item_1.copy_2,
                  type: 'bold'
                }
              ]
            },
            {
              order: 'row',
              description: data.content.Statistics.item_2.description,
              lines: [
                {
                  copy: data.content.Statistics.item_2.copy_1,
                  type: 'bold'
                },
                {
                  copy: data.content.Statistics.item_2.copy_2,
                  type: ''
                }
              ]
            },
            {
              order: 'cols',
              description: data.content.Statistics.item_3.description,
              lines: [
                {
                  copy: data.content.Statistics.item_3.copy_1,
                  type: 'bold'
                },
                {
                  copy: data.content.Statistics.item_3.copy_2,
                  type: ''
                }
              ]
            }
          ]}
        />

        <BackgroundHalfGrey>
          <HighlightBlockNoImage
            Theme={'Dark-Primary'}
            AdditionalDescription={data.content.HighlightBlock.description}
            Title={data.content.HighlightBlock.title}
            Button={{
              copy: data.content.HighlightBlock.button.copy,
              SourceID: 'download-brochure',
              ModelTitle: data.content.HighlightBlock.button.modalTitle,
              OpenDemoModel: true
            }}
          />
        </BackgroundHalfGrey>
      </BackgroundCircles>
      <BackgroundGrey>
        <AnchoredContent
          Title={data.content.AnchoredContent.title}
          Items={[
            {
              img: '/assets/images/dental/printing.png',
              anchor: data.content.AnchoredContent.item_1.anchor,
              title: data.content.AnchoredContent.item_1.title,
              copy: data.content.AnchoredContent.item_1.copy
            },
            {
              img: '/assets/images/dental/production.png',
              anchor: data.content.AnchoredContent.item_2.anchor,
              title: data.content.AnchoredContent.item_2.title,
              copy: data.content.AnchoredContent.item_2.copy
            },
            {
              img: '/assets/images/dental/milling.png',
              anchor: data.content.AnchoredContent.item_3.anchor,
              title: data.content.AnchoredContent.item_3.title,
              copy: data.content.AnchoredContent.item_3.copy
            },
            {
              img: '/assets/images/dental/management.png',
              anchor: data.content.AnchoredContent.item_4.anchor,
              title: data.content.AnchoredContent.item_4.title,
              copy: data.content.AnchoredContent.item_4.copy
            }
          ]}
        />
      </BackgroundGrey>
      <ClientSpotlight
        Title={data.content.ClientSpotlight.title}
        Items={[
          {
            Logo: '/assets/images/industry-partners/3DRPD.png',
            Copy: data.content.ClientSpotlight.copy_1
          },
          {
            Logo: '/assets/images/industry-partners/sinteo.png',
            Copy: data.content.ClientSpotlight.copy_2
          },
          {
            Logo: '/assets/images/industry-partners/zyx.png',
            Copy: data.content.ClientSpotlight.copy_3
          }
        ]}
      />
      <SplitColumn
        ImagePosition={'Right'}
        Copy={data.content.VideoBlock.copy}
        Background={'Off-White'}
        MediaType={'Video'}
        Video={'https://play.vidyard.com/G8tWvS54bkCxUK4cNFaPfe?'}
        Button={{
          copy: data.content.VideoBlock.button,
          theme: 'primary',
          link: '/news/customer-stories/insane-cost-and-productivity-improvements/'
        }}
      />

      <ArticleBlock
        Title={data.content.CustomerStories.title}
        Layout={'layout-3'}
        Items={[
          {
            title: data.content.CustomerStories.news.item_1.title,
            link: '/posts/oqcams-innovative-software-multiplies-our-production-efficiency/',
            image: '/assets/images/customerStories/ideas/IMG-1.png',
            category: data.content.CustomerStories.news.item_1.category,
            readTime: data.content.CustomerStories.news.item_1.readTime
          },
          {
            title: data.content.CustomerStories.news.item_2.title,
            link: '/posts/a-mind-blowing-step-forward-thanks-to-oqcam/',
            image: '/assets/images/customerStories/teamziereis/img-1.png',
            category: data.content.CustomerStories.news.item_2.category,
            readTime: data.content.CustomerStories.news.item_2.readTime
          },
          {
            title: data.content.CustomerStories.news.item_3.title,
            link: '/posts/dentistry-without-the-labor-the-journey-to-automation/',
            image: '/assets/images/customerStories/mclw/mclw_3.jpg',
            category: data.content.CustomerStories.news.item_3.category,
            readTime: data.content.CustomerStories.news.item_3.readTime
          }
        ]}
      />

      <BackgroundHalfGrey>
        <HighlightBlockNoImage
          Theme={'Dark-Primary'}
          Title={data.content.RequestADemo.title}
          Button={{
            SourceID: 'request-demo',
            copy: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true
          }}
        />
      </BackgroundHalfGrey>
      <BackgroundGrey>
        <ArticleBlock
          Title={data.content.Articles.title}
          Link={{
            copy: data.content.Articles.linkCopy,
            link: '/news/'
          }}
          Layout={'layout-2'}
          Items={[
            {
              link: '/posts/dental-automation/',
              image: '/assets/images/dental/dental.png',
              title: data.content.Articles.items.item_1.title,
              category: data.content.Articles.items.item_1.category,
              readTime: data.content.Articles.items.item_1.readTime
            },
            {
              link: '/posts/how-to-connect-your-clear-aligner-production-system/',
              image: 'https://app.dropinblog.com/uploaded/blogs/34247473/files/ClearAlignerNest.png',
              title: data.content.Articles.items.item_2.title,
              category: data.content.Articles.items.item_2.category,
              readTime: data.content.Articles.items.item_2.readTime
            },
            {
              link: '/posts/how-dental-labs-can-increase-productivity-lower-costs-with-oqcam/',
              image: 'https://app.dropinblog.com/uploaded/blogs/34247473/files/WorfklowInMOS.png',
              title: data.content.Articles.items.item_3.title,
              category: data.content.Articles.items.item_3.category,
              readTime: data.content.Articles.items.item_3.readTime
            }
          ]}
        />
      </BackgroundGrey>

      <LogoGrid
        Title={data.content.LogoGrid.title}
        Logos={[
          {
            src: '/assets/images/industry-partners/EOS.png',
            alt: 'EOS'
          },
          {
            src: '/assets/images/industry-partners/Renishaw.png',
            alt: 'Renishaw'
          },
          {
            src: '/assets/images/industry-partners/HP.png',
            alt: 'HP'
          },
          {
            src: '/assets/images/industry-partners/E-Plus-3D.png',
            alt: 'E-Plus-3D'
          },
          {
            src: '/assets/images/industry-partners/Labtrac.png',
            alt: 'Labtrac'
          },
          {
            src: '/assets/images/industry-partners/Trumpf.png',
            alt: 'Trumpf'
          },
          {
            src: '/assets/images/industry-partners/3DSystems.png',
            alt: '3DSystems'
          },
          {
            src: '/assets/images/industry-partners/Roland.png',
            alt: 'Roland'
          },
          {
            src: '/assets/images/industry-partners/Sisma.png',
            alt: 'Sisma'
          },
          {
            src: '/assets/images/industry-partners/DMGMori.png',
            alt: 'DMGMori'
          },
          {
            src: '/assets/images/industry-partners/SLMSolutions.png',
            alt: 'SLMSolutions'
          },
          {
            src: '/assets/images/industry-partners/ConceptLaser(GE).png',
            alt: 'ConceptLaser(GE)'
          },
          {
            src: '/assets/images/industry-partners/nexa3D.png',
            alt: 'nexa3D'
          },
          {
            src: '/assets/images/industry-partners/imes-icore.png',
            alt: 'imes-icore'
          },
          {
            src: '/assets/images/industry-partners/PRODWAYS.png',
            alt: 'PRODWAYS'
          },
          {
            src: '/assets/images/industry-partners/Stratasys.png',
            alt: 'Stratasys'
          },
          {
            src: '/assets/images/industry-partners/2onelab.png',
            alt: '2onelab'
          }
        ]}
      />

      <FooterButtonOverlay
        Title={data.content.Footer.title}
        Button={{
          SourceID: 'request-trial',
          OpenDemoModel: true,
          Label: globalCopy.Buttons.RequestFreeTrial
        }}
      />
      <Footer lang={Lang} additionalClasses={'additional-top-padding'} />
    </PageContainer>
  );
};

export default HealthcareDE;
